@import url('./components/header.css');
@import url('./components/mainSecion.css');
@import url('./components/footer.css');
@import url('./components/nav.css');
@import url('./pages/articlePage.css');

.loading-sign,
.error-sign
 {
    margin: 50px 0 300px;
    text-align: center;
}

body {
    padding-top: 67px;
}

@media screen and (max-width: 670px){
    body {
        padding-top: 95px;
    }
}